<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Consolidar cobros'">
      <template v-slot:actions>
        <v-btn v-if="repayments.length > 0" small @click="downloadRepayments()">
          <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
          Descargar lista
        </v-btn>
      </template>
    </Banner>

    <v-row class="my-5">
      <v-col v-if="user.role === 'SUPERADMIN' || user.role === 'ADMIN'" cols="12" md="4">
        <v-select
          v-model="selectedPartner"
          :items="partners"
          label="Partner"
          :item-text="'name'"
          item-value="id"
          hide-details
          outlined
          dense
          :loading="isLoadingOffices || isLoadingRepayments"
          :disabled="isLoadingOffices || isLoadingRepayments"
          @change="getOffices()"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedOffice"
          :items="offices"
          label="Oficina"
          :item-text="'name'"
          item-value="id"
          hide-details
          outlined
          dense
          :loading="isLoadingOffices || isLoadingRepayments"
          :disabled="isLoadingOffices || isLoadingRepayments"
          @change="getRepaymentsByOffice(), getTechnicians()"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedTechnician"
          :items="technicians"
          label="Técnico"
          :item-text="'full_name'"
          item-value="id"
          hide-details
          outlined
          dense
          clearable
          :loading="isLoadingOffices || isLoadingTechnicians || isLoadingRepayments"
          :disabled="isLoadingOffices || isLoadingTechnicians || isLoadingRepayments"
          @click:clear="getRepaymentsByOffice()"
          @change="getRepaymentsByTechnician()"
        />
      </v-col>
    </v-row>

    <v-card class="mb-8">
      <v-card-title class="d-flex align-center mb-5">
        <p class="mb-0">Cobros a consolidar ({{ totalCountRepayments }})</p>
        <v-spacer />
        <v-text-field v-model="search" append-icon="mdi-magnify" solo dense label="Buscar" hide-details />
      </v-card-title>

      <v-data-table
        :headers="repaymentsTableHeaders"
        :items="repayments"
        hide-default-footer
        multi-sort
        :options="{ sortBy: ['paid_at', 'created_at'], sortDesc: [true, true] }"
        :search="search"
        :items-per-page="-1"
        :loading="isLoadingRepayments"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <router-link :to="`/proyectos/${item.project_id}`">{{ item.project_id }}</router-link>
            </td>
            <td>
              <router-link v-if="item.borrower" :to="`/prestatarias/${item.borrower.id}`">{{ item.borrower.name }}</router-link>
              <p v-else class="mb-0">-</p>
            </td>
            <td>
              <router-link v-if="item.office" :to="`/oficinas/${item.office.id}`">{{ item.office.name }}</router-link>
              <p v-else class="mb-0">-</p>
            </td>
            <td>
              <router-link v-if="item.technician" :to="`/tecnicos/${item.technician.id}`">{{ item.technician.name }}</router-link>
              <p v-else class="mb-0">-</p>
            </td>
            <td>{{ formatDate(item.paid_at) }}</td>
            <td>
              {{ formatDate(item.created_at) }}
            </td>
            <td>{{ currency(item.amount, { fromCents: true }) }}</td>
            <td>{{ item.receipt_number }}</td>
            <td>
              <v-icon v-if="item.receipt_photo" @click="getUrlAndOpen(item.receipt_photo)" title="Ver foto del cobro">image</v-icon>
              <span v-else>-</span>
            </td>
            <!-- <td>
                  <p class="mb-0" v-if="item.payment_breakdown">
                    {{ (currency(item.amount, { fromCents: true }) - item.payment_breakdown.total).toFixed(2) }}
                  </p>
                  <p v-else class="mb-0">Faltan datos</p>
                </td> -->
            <td><v-checkbox :input-value="isDuplicateRepayment(item)" dense readonly hide-details class="mb-2" /></td>
            <td>
              <v-btn
                v-if="!item.consolidated && item.status === 'CREATED'"
                color="info"
                x-small
                class="ml-5"
                :disabled="!permissions.consolidate.includes(user.role)"
                @click="(selectedRepayment = item), (consolidateRepaymentDialog = true)"
              >
                consolidar
              </v-btn>
              <v-btn
                v-if="!item.consolidated && item.status === 'REVIEWED'"
                color="warning"
                x-small
                class="ml-5"
                :disabled="!permissions.unconsolidate.includes(user.role)"
                @click="(selectedRepayment = item), (consolidateRepaymentDialog = true)"
              >
                desconsolidar
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialog consolidate/unconsolidate repayment -->
    <DialogBox
      v-if="selectedRepayment"
      :model="consolidateRepaymentDialog"
      :color="selectedRepayment.status === 'CREATED' ? 'info' : 'warning'"
      isdark
    >
      <template slot="toolbar">
        <span>¿Estás seguro de {{ selectedRepayment.status === "CREATED" ? "consolidar" : "desconsolidar" }} este cobro?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="consolidateRepaymentDialog = false">Cerrar</v-btn>
        <v-btn
          v-if="selectedRepayment.status === 'CREATED'"
          text
          @click="(consolidateRepaymentDialog = false), changeRepaymentToReviewed()"
        >
          consolidar
        </v-btn>
        <v-btn v-else text @click="(consolidateRepaymentDialog = false), returnRepaymentToCreatedStatus()">desconsolidar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import elasticSearchApi from "@/api/elasticSearchApi";
import * as currency from "currency.js";

export default {
  name: "Partner",
  components: {
    Banner,
    DialogBox
  },
  mixins: [CommonMixin, FormRulesMixin],
  data() {
    return {
      isLoadingRepayments: false,
      isLoadingOffices: false,
      isLoadingTechnicians: false,
      repayments: [],
      totalCountRepayments: 0,
      search: "",

      repaymentsTableHeaders: [
        { text: "Proyecto", value: "project_id", sortable: false },
        { text: "Prestataria", value: "borrower.name", sortable: false, width: "170px" },
        { text: "Oficina", value: "office.name", sortable: false, width: "170px" },
        { text: "Técnico", value: "technician.name", sortable: false, width: "170px" },
        { text: "Fecha de cobro", value: "paid_at", width: "120px" },
        { text: "Fecha de creación", value: "created_at", width: "120px" },
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "Nº Recibo", value: "receipt_number", sortable: false },
        { text: "Imagen", value: "receipt_photo", sortable: false },
        // { text: "Cobro diferente", value: "different_repayment", sortable: false },
        { text: "Cobro duplicado", value: "duplicate_repayment", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],

      selectedRepayment: null,
      consolidateRepaymentDialog: false,

      // Filters
      partners: [],
      selectedPartner: "",
      offices: [],
      selectedOffice: "",
      technicians: [],
      selectedTechnician: "",

      // Repayments elastic data
      elasticData: null,

      // Repayment permissions
      permissions: {
        consolidate: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"],
        unconsolidate: ["SUPERADMIN"]
      }
    };
  },
  computed: {
    ...mapState("user", ["user", "dbUser"]),
    currency() {
      return currency;
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.$store.dispatch("user/getDbUserInfo");
    if (this.user.role === "SUPERTECHNICIAN") await this.getOffices();
    else await this.getPartners();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      getRepayments: "projects/getRepayments"
    }),

    async changeRepaymentToReviewed() {
      await this.$store.dispatch("projects/changeRepaymentToReviewed", this.selectedRepayment.id);

      this.$store.commit("SET_LOADING_LISTENER", true);
      const { data } = await this.getRepayments(this.elasticData);
      this.repayments = data.data;
      this.totalCountRepayments = data.data.filter((e) => e.status === "CREATED" && !e.consolidated).length;
      this.$store.commit("SET_LOADING_LISTENER", false);
    },

    async returnRepaymentToCreatedStatus() {
      await this.$store.dispatch("projects/returnRepaymentToCreatedStatus", this.selectedRepayment.id);

      this.$store.commit("SET_LOADING_LISTENER", true);
      const { data } = await this.getRepayments(this.elasticData);
      this.repayments = data.data;
      this.totalCountRepayments = data.data.filter((e) => e.status === "CREATED" && !e.consolidated).length;
      this.$store.commit("SET_LOADING_LISTENER", false);
    },

    isDuplicateRepayment(repayment) {
      return (
        this.repayments.filter(
          (e) => e.project_id === repayment.project_id && e.paid_at === repayment.paid_at && e.amount === repayment.amount
        ).length > 1
      );
    },

    async getRepaymentsByOffice() {
      this.isLoadingRepayments = true;
      this.elasticData = { filter: { status: ["CREATED", "REVIEWED"], "office.id": this.selectedOffice } };
      const { data } = await this.getRepayments(this.elasticData);
      this.repayments = data.data;
      this.totalCountRepayments = data.data.filter((e) => e.status === "CREATED" && !e.consolidated).length;
      this.isLoadingRepayments = false;
    },

    async getRepaymentsByTechnician() {
      if (this.selectedTechnician) {
        this.isLoadingRepayments = true;
        this.elasticData = { filter: { status: ["CREATED", "REVIEWED"], "technician.id": this.selectedTechnician } };
        const { data } = await this.getRepayments(this.elasticData);

        this.repayments = data.data;
        this.totalCountRepayments = data.data.filter((e) => e.status === "CREATED" && !e.consolidated).length;
        this.isLoadingRepayments = false;
      }
    },

    async getPartners() {
      try {
        const response = await elasticSearchApi.post("/partner");
        const sortedPartners = this.sortArray(response.data.data, "name");
        this.partners = sortedPartners;
      } catch (error) {
        console.log(error);
      }
    },

    async getOffices() {
      this.isLoadingOffices = true;
      let data = { filter: { "partner.id": this.selectedPartner } };
      if (this.user.role === "SUPERTECHNICIAN") data = { filter: { "partner.id": this.dbUser.technician.partner_id } };

      try {
        const response = await elasticSearchApi.post("/office", data);
        const sortedOffices = this.sortArray(response.data.data, "name");
        this.offices = sortedOffices;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingOffices = false;
      }
    },

    async getTechnicians() {
      this.isLoadingTechnicians = true;
      let data = { filter: { "office.id": this.selectedOffice } };

      try {
        const response = await elasticSearchApi.post("/technician", data);
        const sortedTechnicians = this.sortArray(response.data.data, "full_name");
        this.technicians = sortedTechnicians;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingTechnicians = false;
      }
    },

    // -------------- Other methods --------------
    sortArray(array, name) {
      return array.sort((a, b) => a[name].localeCompare(b[name]));
    },

    amountGreaterThanDouble(item) {
      if (item.payment_breakdown && item.payment_breakdown.total) {
        const repaymentAmount = currency(item.amount, { fromCents: true }).value;
        const requiredAmount = item.payment_breakdown.total;

        return repaymentAmount - requiredAmount > requiredAmount * 2;
      }
    },

    async downloadRepayments() {
      const { data: path } = await this.$store.dispatch("projects/downloadUnconsolidatedRepayments", this.selectedOffice);
      window.open(path, "_blank");
    }
  }
};
</script>
